@charset "utf-8";
#IndexContainer #RandomProductsContainer{
    text-align: center;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer{
    margin: 0;
    padding: 0;
    
}
#IndexContainer #RandomProductsContainer #RandomProductSubContainer_1{
    background-color: #E5F9F5;
}
#IndexContainer #RandomProductsContainer #RandomProductSubContainer_2{
    background-color: #E5F4FE;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductsCenteredContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Image{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Name,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_SubName,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Size{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Name{
    color: #153768;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_SubName,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Size{
    color: #00C7D6;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductPrice{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton{
    border: none;
    background-color: #00C7D6;
    transition: .3s;
    cursor: pointer;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton:hover{
    transform: scale(1.1);
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonIcon,
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonText{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonText{
    color: #ffffff;
    font-weight: bold;
}
#IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductPrice{
    color: #00C7D6;
}
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
#IndexContainer #SegmentProductsContainer{
    text-align: left;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_SpecialOfert{
    background-color: #DFF8F1;
}
#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_NewProduct{
    background-color: #FFFBDE;
}
#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_StarProduct{
    background-color: #DCEAFB;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    text-align: right;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_Image,
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextTitle,
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle,
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextPrice,
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextViewMoreLink{
    margin: 0;
    padding: 0;
    display: block;
    
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextTitle{
    color: #435254;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle{
    color: #000000;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextPrice{
    color: #435254;
}
#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextViewMoreLink{
    color: #435254;
    cursor: pointer;
}
#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_SpecialOfert .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle{
    background-color: #C3EADA;
}
#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_NewProduct .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle{
    background-color: #F1E8B7;
}
#IndexContainer #SegmentProductsContainer #SegmentProductSubContainer_StarProduct .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle{
    background-color: #B2D2F3;
}

@media screen and (min-width: 701px) {
    #IndexContainer #RandomProductsContainer{
        margin: 3vh auto;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer{
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin: 1vh 2% 0 0;
        padding: 3vh 3%;
        width: 41.5%;
        height: 25vh;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text{
        margin: 0 2%;
        width: 50%;
        text-align: left;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Name{
        font-size: 1.8em;
        font-weight: bold;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_SubName{
        font-size: 1.6em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Size{
       font-size: 0.9em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton{
        padding: 10px 15px;
        margin: 3vh 10px 3vh 0;
        border-radius: 5px 5px 5px 5px;
        -moz-border-radius: 5px 5px 5px 5px;
        -webkit-border-radius: 5px 5px 5px 5px;
        border: 0px solid #000000;
        border: 0px solid #000000;
        
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonIcon{
        width: 20px;
        padding-right: 5px;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonText{
        font-size: .9em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductPrice{
        font-size: 1.2em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Image{
        width: 40%;
        max-height: 25vh !important;
    }
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    #IndexContainer #SegmentProductsContainer{
        margin: 3vh auto;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer{
        margin: 1vh 2% 0 0;
        width: 30%;
        height: 25vh;
    }
    /*#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered{
    }*/
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_Image{
        width: 35%;
        margin-right: 2%;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer{
        width: 60%;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextTitle,
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle,
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextPrice,
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextViewMoreLink{
        padding: 5px 20px 5px 10px;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextTitle{
        font-size: .8em;
        margin-bottom: 1vh;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle{
        font-size: 1.1em;
        margin-bottom: 1vh;
        border-radius: 20px 0px 0px 20px;
        -moz-border-radius: 20px 0px 0px 20px;
        -webkit-border-radius: 20px 0px 0px 20px;
        border: 0px solid #000000;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextPrice{
        font-size: 1.2em;
        margin-bottom: 3vh;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextViewMoreLink{
        font-size: .8em;
    }
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    #IndexContainer #DiscountMessageContainer{
       padding: 8vh 0;
       margin: 10vh 0;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer,
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer{
        text-align: left;
        width: 30%;
        margin-right: 10%;
        margin-right: 1%;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageTitle{
        font-size: 1.8em;
        margin-bottom: 1vh;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageSubTitle{
        font-size: 1.4em;
        margin-bottom: 1vh;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageText{
        font-size: 1.1em;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{
        text-align: left;
        width: 40%;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageInput{
        padding: 10px 20px;
        margin: 0;
        width: 50%;
        border-radius: 7px 0px 0px 7px;
        -moz-border-radius: 7px 0px 0px 7px;
        -webkit-border-radius: 7px 0px 0px 7px;
        border: 0px solid #000000;
        font-size: .9em;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton{
        padding: 10px 20px;
        margin: 0 0 0 -10px;
        font-size: .9em;
        border-radius: 0px 7px 7px 0px;
        -moz-border-radius: 0px 7px 7px 0px;
        -webkit-border-radius: 0px 7px 7px 0px;
        border: 0px solid #000000;
    }
    /*--------------------------------------*/
    /*--------------------------------------*/
    /*--------------------------------------*/
    #SEMContainer{
        margin: 0;
        display: inline-block;
        width: 50%;
        height: 350px;
        padding: 2%;
        vertical-align: middle;
    }
    #SEMContainer #SEMSubContainer{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SEMContainer #SEMSubContainer #SEMTMiniTitle{
        margin-bottom: 25px;
        width: 60%;
    }
    #SEMContainer #SEMSubContainer #SEMtitle{
        width: 60%;
        font-size: 2em;
    }
    #SEMContainer #SEMSubContainer #SEMSubTitle{
        width: 60%;
        font-size: 1.2em;
    }
    #SEMContainer #SEMSubContainer #PriceContainer{
        padding-bottom: 7px;
    }
    #SEMContainer #SEMSubContainer #PriceContainer #SEMDiscountPrice{
        font-size: 2em;
    }
    #SEMContainer #SEMSubContainer #PriceContainer #SEMNormalPrice{
        font-size: 1.2em;
    }
    #SEMContainer #SEMSubContainer #SEMCounterContainer{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer{
        width: 65px;
        padding: 12px 7px;
        margin-right: 7px;
    }
    
    #SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer .SEMCounterSubContainerNumber{
        font-size: 1.8em;
    }
    #SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer .SEMCounterSubContainerText{
        font-size: .8em;
    }
    /*--------------------------------------*/
    /*--------------------------------------*/
    /*--------------------------------------*/
    #ReviewIndexContainer{
        margin: 0;
        display: inline-block;
        width: 40%;
        height: 350px;
        padding: 2%;
        vertical-align: middle;
    }
    #ReviewContainer{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    #ReviewContainer #ReviewTitle{
        padding: 1vh;
        margin-top: -30px;
        font-size: 1.7em;
        width: 200px;
    }
    #ReviewContainer #review_mark{
        width: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #ReviewContainer #ReviewList{
        height: 220px;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem{
        
    }*/
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList{
        padding: 1vh 0;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem{
        
    }*/
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem .ReviewListItemStarListItemImage{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemAuthor{
        font-size: 1.2em;
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemParagraph{
        font-size: 1.2em;
    }
    /*#ReviewContainer #ReviewButtonsList{
        
    }*/
    #ReviewContainer #ReviewButtonsList .ReviewButtonsListItem{
        padding: 0 3px;
        width: 20px;
    }
}
@media screen and (max-width: 700px) {
	#IndexContainer #RandomProductsContainer{
        margin: 3vh auto;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer{
        display: block;
        text-align: center;
        margin: 2vh auto;
        padding: 3vh 3%;
        width: 90%;
        height: 25vh;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text{
        margin: 0 2%;
        width: 50%;
        text-align: left;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Name{
        font-size: 1.8em;
        font-weight: bold;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_SubName{
        font-size: 1.6em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductNameContainer .RandomProductsSubContainer_Size{
       font-size: 0.9em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton{
        padding: 10px 15px;
        margin: 3vh 10px 3vh 0;
        border-radius: 5px 5px 5px 5px;
        -moz-border-radius: 5px 5px 5px 5px;
        -webkit-border-radius: 5px 5px 5px 5px;
        border: 0px solid #000000;
        border: 0px solid #000000;
        
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonIcon{
        width: 20px;
        padding-right: 5px;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductBuyButton .RandomProductSubContainer_TextProductBuyButtonText{
        font-size: .9em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Text .RandomProductSubContainer_TextProductButton .RandomProductSubContainer_TextProductPrice{
        font-size: 1.2em;
    }
    #IndexContainer #RandomProductsContainer .RandomProductSubContainer .RandomProductSubContainer_Image{
        width: 40%;
        max-height: 25vh !important;
    }
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    #IndexContainer #SegmentProductsContainer{
        margin: 3vh auto;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer{
        margin: 2vh 2% 0 0;
        width: 100%;
        height: 25vh;
    }
    /*#IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered{
    }*/
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_Image{
        width: 35%;
        max-height: 23vh;
        margin-right: 2%;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer{
        width: 60%;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextTitle,
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle,
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextPrice,
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextViewMoreLink{
        padding: 5px 20px 5px 10px;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextTitle{
        font-size: .8em;
        margin-bottom: 1vh;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextProductTitle{
        font-size: 1.1em;
        margin-bottom: 1vh;
        border-radius: 20px 0px 0px 20px;
        -moz-border-radius: 20px 0px 0px 20px;
        -webkit-border-radius: 20px 0px 0px 20px;
        border: 0px solid #000000;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextPrice{
        font-size: 1.2em;
        margin-bottom: 3vh;
    }
    #IndexContainer #SegmentProductsContainer .SegmentProductSubContainer .SegmentProductSubContainerCentered .SegmentProductSubContainer_TextContainer .SegmentProductSubContainer_TextViewMoreLink{
        font-size: .8em;
    }
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    /*----------------------------------------------------*/
    #IndexContainer #DiscountMessageContainer{
       padding: 8vh 0;
       margin: 10vh 0;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer,
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{
        margin: auto;
        padding: 0;
        display: block;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer{
        text-align: left;
        width: 90%;
        margin-bottom: 5vh;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageTitle{
        font-size: 1.8em;
        margin-bottom: 1vh;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageSubTitle{
        font-size: 1.4em;
        margin-bottom: 1vh;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageText{
        font-size: 1.1em;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{
        text-align: left;
        width: 40%;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageInput{
        padding: 10px 20px;
        margin: 0;
        width: 50%;
        border-radius: 7px 0px 0px 7px;
        -moz-border-radius: 7px 0px 0px 7px;
        -webkit-border-radius: 7px 0px 0px 7px;
        border: 0px solid #000000;
        font-size: .9em;
    }
    #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton{
        padding: 10px 20px;
        margin: 0 0 0 -10px;
        font-size: .9em;
        border-radius: 0px 7px 7px 0px;
        -moz-border-radius: 0px 7px 7px 0px;
        -webkit-border-radius: 0px 7px 7px 0px;
        border: 0px solid #000000;
    }
    /*--------------------------------------*/
    /*--------------------------------------*/
    /*--------------------------------------*/
    #SEMContainer{
        margin: auto;
        display: block;
        width: 96%;
        height: 350px;
        padding: 2%;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(21,55,104,1);
        -moz-box-shadow: 0px 0px 5px 0px rgba(21,55,104,1);
        box-shadow: 0px 0px 5px 0px rgba(21,55,104,1);
    }
    #SEMContainer #SEMSubContainer{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }
    #SEMContainer #SEMSubContainer #SEMTMiniTitle{
        margin-bottom: 15px;
        width: 80%;
    }
    #SEMContainer #SEMSubContainer #SEMtitle{
        width: 80%;
        font-size: 2em;
    }
    #SEMContainer #SEMSubContainer #SEMSubTitle{
        width: 60%;
        font-size: 1.2em;
    }
    #SEMContainer #SEMSubContainer #PriceContainer{
        padding-bottom: 7px;
    }
    #SEMContainer #SEMSubContainer #PriceContainer #SEMDiscountPrice{
        font-size: 1.8em;
    }
    #SEMContainer #SEMSubContainer #PriceContainer #SEMNormalPrice{
        font-size: 1.2em;
    }
    #SEMContainer #SEMSubContainer #SEMCounterContainer{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    #SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer{
        width: 50px;
        padding: 9px 7px;
        margin-right: 7px;
    }
    
    #SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer .SEMCounterSubContainerNumber{
        font-size: 1.4em;
    }
    #SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer .SEMCounterSubContainerText{
        font-size: .8em;
    }
    /*--------------------------------------*/
    /*--------------------------------------*/
    /*--------------------------------------*/
    #ReviewIndexContainer{
        margin: 0;
        margin-top: 8vh;
        display: block;
        width: 96%;
        height: 350px;
        padding: 2%;
    }
    #ReviewContainer{
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    #ReviewContainer #ReviewTitle{
        padding: 1vh;
        margin-top: -30px;
        font-size: 1.7em;
        width: 200px;
    }
    #ReviewContainer #review_mark{
        width: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #ReviewContainer #ReviewList{
        height: 220px;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem{
        
    }*/
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList{
        padding: 1vh 0;
    }
    /*#ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem{
        
    }*/
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemStarList .ReviewListItemStarListItem .ReviewListItemStarListItemImage{
        
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemAuthor{
        font-size: 1.2em;
    }
    #ReviewContainer #ReviewList .ReviewListItem .ReviewListItemParagraph{
        font-size: 1.2em;
    }
    /*#ReviewContainer #ReviewButtonsList{
        
    }*/
    #ReviewContainer #ReviewButtonsList .ReviewButtonsListItem{
        padding: 0 3px;
        width: 20px;
    }
}
